import React from "react";
import * as helper from "../../../helper/helper";

function CustomerBearerInfo({ user }) {
  const signUpDateSecond = user ? user.sign_up_date._seconds : "";
  const signUpDateNanoSecond = user ? user.sign_up_date._nanoseconds : "";
  const point = user ? user.bearer_points : "";
  const userType = user ? user.user_type : "";
  return (
    <div>
      <h5>Customer Bearer Info</h5>
      <div className="row">
        <div className="col-md-3">
          <div className="thumbnail">
            <div className="title">Signup Date</div>
            <div className="value">
              {helper.timestampToDateYearNanoSecond(
                signUpDateSecond,
                signUpDateNanoSecond
              )}
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="thumbnail">
            <div className="title">Point</div>
            <div className="value">{point}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="thumbnail">
            <div className="title">Type</div>
            <div className="value">{userType}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="thumbnail">
            <div className="title">Agreed TC-PP</div>
            <div className="value">
              {user && user.latest_tc_pp_vers_signed_accepted
                ? user.latest_tc_pp_vers_signed_accepted
                : ""}
            </div>
          </div>
        </div>
        {user &&
        user.latest_tc_pp_vers_signed_date &&
        user.latest_tc_pp_vers_signed_date._seconds ? (
          <div className="col-md-4">
            <div className="thumbnail">
              <div className="title">Agreed TC-PP Date</div>
              <div className="value">
                {helper.timestampToDateYearAndTime(
                  user.latest_tc_pp_vers_signed_date._seconds,
                  user.latest_tc_pp_vers_signed_date._nanoseconds
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default CustomerBearerInfo;
